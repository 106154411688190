<template>
	<v-dialog v-model="editMode" persistent max-width="600px">
		<template #activator="{ on }">
			<v-btn text small rounded plain color="primary" v-on="on">
				{{ $t('settings.edit') }}
			</v-btn>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('settings.edit') }}</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-card class="my-1" flat rounded="xl">
						<v-card-title>
							{{ language.name }}
						</v-card-title>
						<v-card-text>
							<v-select
								v-model="language.level"
								:label="$t('cv.languageLevel')"
								:items="availableLanguageLevels"
								item-text="name"
								item-value="key"
								:rules="rules.level"
								return-object
								outlined
							/>
						</v-card-text>
						<v-card-actions>
							<v-btn text rounded color="primary" @click="callEditLanguage(language)">
								{{ $t('settings.edit') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" text rounded @click="switchEditMode()">{{ $t('search.close') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'CVLanguagesEdit',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		},
		language: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			editMode: false,
			valid: false,
			rules: {
				level: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && this.availableLanguageLevels.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported')
				]
			}
		}
	},
	computed: {
		...mapGetters({
			availableLanguageLevels: 'data/availableLanguageLevels',
			user: 'users/user'
		})
	},
	created() {
		this.loading = true
		this.fetchAvailableLanguageLevels().then(() => {
			this.loading = false
		})
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		callEditLanguage(language) {
			this.loading = true
			this.editLanguage({
				userID: this.user.id,
				languageID: language.id,
				level: language.level
			}).then(() => {
				this.loading = false
				this.switchEditMode()
			})
		},
		...mapActions('cv', ['editLanguage']),
		...mapActions('data', ['fetchAvailableLanguageLevels'])
	}
}
</script>
